import React from "react"
import { graphql } from "gatsby"
import { Row, Col, Container } from "reactstrap"
import PhotoViewerDark from "../components/photoViewerDark"
import List1 from "../components/list1"

import BackArrow from "../assets/svg/arrow.svg"
// import User from "../assets/svg/solid/user.svg"
// import Pin from "../assets/svg/solid/map-marker-alt.svg"
// import Calendar from "../assets/svg/solid/calendar-alt.svg"
import Bill from "../assets/svg/solid/money-bill-wave.svg"
// import CalendarCheck from "../assets/svg/solid/calendar-check.svg"
import Hardhat from "../assets/svg/solid/hard-hat.svg"

export const query = graphql`
  query($slug: String!) {
    kunstJson(slug: { eq: $slug }) {
      title
      category
      description
      img {
        src
       
      }
     # completionDate
      amount
     # duration
      # owner
     # address
    }
  }
`

const SingeProject = props => {
  const {
    title,
    category,
    description,
    img,
    // completionDate,
    amount,
    // duration,
    // owner,
    // address,
  } = props.data.kunstJson

  const onBackIconClick = () => {
    window.history.back()
  }

  return (
    <main className="single-project bg-dark section-lg" id="single-project">
      <Container style={{ position: 'relative' }}>
        <BackArrow onClick={onBackIconClick} className="back-arrow white" />
        <Row>
          <Col lg="5" className="project-details">
            <h2 className="header-title text-center text-white text-capitalize">
              {title}
            </h2>
            <p className="description text-white">{description}</p>
            <section className="details mt-5 mb-5">
              {/* <List1
                className="font-size-120"
                svg={<User />}
                title="Owner"
                value={owner}
              /> */}
              {/* <hr />
              <List1
                className="font-size-120"
                svg={<Pin />}
                title="Ort"
                value={address}
              />
              <hr /> */}
              <List1
                className="font-size-120 text-white"
                svg={<Hardhat />}
                title="Material"
                value={category.map((cat, index, category) =>
                  index === category.length - 1 ? cat : cat + ", "
                )}
              />
              <hr />
              <List1
                className="font-size-120 text-white"
                svg={<Bill />}
                title="Kosten"
                value={amount}
              />
              {/* <hr />
              <List1
                className="font-size-120"
                svg={<Calendar />}
                title="Dauer"
                value={duration}
              />
              <hr /> */}
              {/* <List1
                className="font-size-120"
                svg={<CalendarCheck />}
                title="Fertigstellung"
                value={completionDate}
              /> */}
            </section>
          </Col>
          <Col lg="7" className="project-photos bg-dark">
            <PhotoViewerDark list={img} className="bg-dark" />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default SingeProject
